<template>
  <div class="page">
    <div class="wrapper illustration illustration_form">
      <Backlink title="service_payment" :backlink="backlink" />
      <div class="content">
        <div class="content__container">
          <PaymentService :service="service"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import PaymentService from '@/components/PaymentService.vue'

export default {
  name: 'Registration_page',
  components: { Backlink, PaymentService },
  props: {
    service: { type: Number, required: true },
    backlink: { type: String, default: '/' }
  }
}
</script>
